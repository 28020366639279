
// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

//Fontawesome

@import "../../../node_modules/@fortawesome/fontawesome-free/css/all.css";

// Variables
@import 'variables';

// Bootstrap
@import '../../../node_modules/bootstrap/scss/bootstrap';

// SummerNote
@import "../../../node_modules/summernote/dist/summernote-bs4.css";

// Bootstrap Date-Range Picker
@import "../../../node_modules/bootstrap-daterangepicker/daterangepicker.css";


// CodeMirror
@import "../../../node_modules/codemirror/lib/codemirror.css";
.CodeMirror {
  height: auto;
}

.CodeMirror{
  &, &-scroll{
    min-height: 240px;
  }
}


.navbar-laravel {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);

}


//Admin panel Styles=====================
@import "body";
@import "components/all";
@import "dashboard/all";
@import "fields/all";

