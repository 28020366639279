.field-slider{
  .slider-thumbs{
    img{
      margin: 0.25rem;

      &.active{
        box-shadow: $input-btn-focus-box-shadow;
      }
    }
  }
}