.page-container{

  .dashboard-item{
    display: block;
    padding: 20px;
    border-radius: 10px;
    border-left: 3px solid $sidebar-color;

    &:hover{
      text-decoration: none;
    }

    .title{
      text-transform: uppercase;
      color: $sidebar-color;
      font-weight: 600;
      font-size: 0.8rem;
      margin-bottom: 5px;
    }

    .count{
      font-size: 1.5rem;
      font-weight: 600;
      color: $black;
    }
  }
}