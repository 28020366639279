.page-content{

  .sidebar-arrow{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: $primary;
    color: $white;
    text-align: center;
    margin-right: 20px;
    transition: all 350ms;

    &.rotate{
      transform: rotate(180deg);
    }

    i{
      line-height: 40px;
    }
  }


  .sidebar{
  }

  .page{
    padding-left: $sidebar-width;

    &.padding-small{
      padding-left: $sidebar-small-width;
      transition: all 350ms;
    }
  }

  .primary-navbar{
    background-color: $white;
    box-shadow: 1rem 0 1rem rgba(0, 0, 0, 0.15);
  }

  .page-header{
    padding-top: 20px;

    h4{
      padding: 0 20px;
      margin-bottom: 0;
    }

    .breadcrumb{
      padding-left: 20px;
      background-color: #f8fafc;

      .breadcrumb-item{

        a{
          color: $sidebar-color;
        }
        &.active{
          &:before{
            content: "->";
          }
        }
      }

    }
  }

  .page-container{
    padding: 0 20px;
    margin-bottom: 50px;
  }


}




.pagination-wrap{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  background: $light;
  border-radius: $border-radius;
  padding: 0.6rem;
  border: $gray-300 solid 1px;

  .items-count{

    @include media-breakpoint-down('md'){
      margin-bottom: 1rem;
      margin-right: auto;
      margin-left: auto;
    }
  }

  .pagination{
    align-self: end;
    margin-left: auto;
    margin-bottom: 0;

    @include media-breakpoint-down(md){
      margin-right: auto;
      margin-bottom: 1rem;
    }
  }

  @include media-breakpoint-down(sm){
    flex-direction: column;
  }
}




.card-columns {
  @include media-breakpoint-only(sm) {
    column-count: 1;
  }
  @include media-breakpoint-only(md) {
    column-count: 2;
  }
  @include media-breakpoint-only(lg) {
    column-count: 2;
  }
  @include media-breakpoint-only(xl) {
    column-count: 2;
  }
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.hidden {
  display: none;
}

.relative {
  position: relative;
}

.z-0 {
  z-index: 0;
}

.inline-flex {
  display: inline-flex;
}

.text-sm {
  font-size: 0.75rem;
}

.font-medium {
  font-weight: 500;
}

.text-gray-500 {
  color: #999999;
}

.w-5 {
  width: 1.25rem;
}

.h-5 {
  height: 1.25rem;
}

@media (min-width: 640px) {
  .sm\:hidden {
    display: none;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:flex-1 {
    flex-direction: column-reverse;
  }
}