
.sidebar{
  width: $sidebar-width;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $sidebar-color;
  overflow: auto;

  &.left{
    width: $sidebar-small-width;
    transition: all 350ms;

    .sidebar-nav{

      .nav-link{
        text-align: center;

        span{
          display: none;
        }
      }
    }

    .sitename{
      padding: 0;
    }

  }

  .sitename{
    padding: 12px 30px;
    display: block;
    border-bottom: 1px solid rgba(255,255,255,.15);
    margin-bottom: 20px;

    img{
      display: block;
      width: auto;
      max-height: 30px;
      margin: auto;
    }
  }

  .sidebar-nav{

    .separator{
      border-bottom: 1px solid rgba(255,255,255,.15);
      margin: 0.3rem 0;
    }

    .nav-link{
      color: $white;
      transition: all 350ms;

      &:hover{
        color: darken($white, 20%);
      }
    }
  }

}
