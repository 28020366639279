
.media-sidebar{
  position: sticky;
  top: 60px;
}


.media-gallery{

  .col{
    &:hover{
      z-index: 999999
    };
  }

  .media{
    margin-bottom: 0;

    &:hover{
      .media-actions{
        opacity: 1;
      }

      .icon-checked{
        opacity: 0.8;
      }

    }

    .check{
      display: none;

      &:checked{
        + .media-image{

          img{

          }

          .icon-checked{
            opacity: 1;
            background: $primary;
            color: $light;
          }
        }
      }
    }

    .media-image{
      display: block;
      position: relative;
      border-radius: $border-radius;
      overflow: hidden;
      cursor: pointer;
      margin: auto;

      img{
        width: 100%;
        height: auto;
      }
    }

    .media-actions{
      transition: opacity 300ms;
      opacity: 0;
    }

    .icon-checked{
      display: block;
      position: absolute;
      top: 10px;
      left: 10px;
      opacity: 0;
      font-size: 1.2rem;
      padding: 0.6rem;
      background: $light;
      color: $primary;
      border-radius: 50%;
      transition: $transition-base;

    }

  }
}

.gallery-wrap{
  min-height: 50vh;

  &.headless{
    .media-sidebar{
      top: 0;
    }
  }

  &.hover{
    .droppable-area{
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 99999;
      background: $primary;
      color: $light;

      .inner{
        display: block;
        pointer-events: none;
      }

      .manual-upload{
        display: none;
      }
    }
  }


  &.uploading{

    .droppable-area{
      background: $warning;

      .manual-upload{
        background: linear-gradient(
                        -45deg,
                        rgba(255, 255, 255, .2) 25%,
                        transparent 25%,
                        transparent 50%,
                        rgba(255, 255, 255, .2) 50%,
                        rgba(255, 255, 255, .2) 75%,
                        transparent 75%,
                        transparent
        );
        background-size: 50px 50px;
        animation: anim-progress-move 2s linear infinite;
      }
    }
  }


  .droppable-area{
    @extend .rounded;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    opacity: 0.9;
    margin-bottom: 1rem;

    .inner{
      @extend .rounded;
      display: none;
      padding: 3rem;
      border: $light dotted 1px;
    }

    .manual-upload{
      @extend .rounded;
      width: 100%;
      padding: 0.4rem;
      border: $gray-400 dashed 2px;
      color: $gray-600;
    }

    .icon{
      font-size: 3rem;
      padding: 1rem;
    }

    .info{
      font-size: 0.9rem;
      text-transform: uppercase;
      font-weight: $font-weight-bold;
      letter-spacing: 0.07rem;
    }
  }


  .media-wrap{
    position: relative;
    padding: 0.5rem;
    background: $light;
    border-radius: $border-radius $border-radius 0 0;


    .actions{
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      display: none;
      background: $light;
      padding: 0.4rem;
      box-shadow: rgba( $dark, 0.3 ) 0px 3px 3px;
      border-radius: 0 0 $border-radius $border-radius;
    }

    &:hover{
      box-shadow: rgba( $dark, 0.3 ) 0 0 3px;

      .actions{
        display: block;
        text-align: center;
      }
    }
  }

}



.has-loading{

  &.is-loading{
    .show-on-default{
      display: none;
    }
  }

  &:not(.is-loading){
    .show-on-loading{
      display: none;
    }
  }

}


#media-load-more-section{
  .icon{
    display: none;
  }

  &.loading{
    .icon{
      display: inline-block;
    }
    .btn{
      display: none;
    }
  }
}


#modal-media{

  .modal-dialog{
    max-width: 95%;
    margin: 0.8rem auto;
  }

  .modal-media-frame{
    width: 100%;
  }
}



@keyframes anim-progress-move {
  0%{
    background-position: 0 0;
  }
  100%{
    background-position: 50px 50px;
  }
}