.page-container{

  .create-wrap{
    .card-header{
      background-color: $white;
    }

    .card-body{
      background-color: $white;


    }
  }
}