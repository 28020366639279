.field-image{

  .image{
    position: relative;
    img{
      transition: $transition-fade;
    }
  }

  .remove-image{
    position: absolute;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translate( -50%, -50% );
    font-size: 1.8rem;
    display: none;
    background: $light;
    color: $danger;
    transition: $transition-base;
    border-radius: 50%;
    width: 2.6rem;
    height: 2.6rem;
    vertical-align: middle;
    text-align: center;

    i{
      line-height: 2.6rem;
    }

    &:hover{
      background: $danger;
      color: $light;
    }
  }

  &.has-image{

    .image:hover{

      img{
        opacity: 0.5;
      }

      .remove-image{
        opacity: 1;
      }
    }

    .remove-image{
      display: block;
    }
  }
}