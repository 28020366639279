.table-wrap{
  //background-color: $white;
  padding: 10px;
  border-radius: 5px;

  tbody{

    tr{

      &:hover{

        .actions{
          opacity: 1;
        }

      }

      .page-item-link{
        font-weight: 600;
        font-size: 1.1rem;
        color: $primary;

        &:hover{
          text-decoration: none;
        }
      }

      .actions{
        opacity: 0;
        transition: all 200ms;
        .action-link{
          padding:3px 7px;
          border-radius: 4px;
          color: $white;
          font-size: 0.7rem;
          display: inline-block;
          margin: 0 3px;


          &:hover{
            text-decoration: none;
          }
        }
      }

    }
  }


}